.header {
  padding: 10px;
  position: absolute;
  width: 100%;
  background-color: #fff;

  .burger {
    border-radius: 7px;
    background: #efefef;
    padding: 5px;
    img {
      width: 24px;
      height: 24px;
    }
  }

  &__left {
    .logo {
      img {
        display: block;
        width: 140px;
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav {
      margin-left: 100px;
      display: none;
      ul {
        display: flex;
        align-items: center;
        li {
          &:not(:last-child) {
            margin-right: 48px;
          }
          a {
            color: #192c50;
            font-size: 16px;
          }
        }
      }
    }
  }
  &__action {
    margin-left: 0px;
    ul {
      display: flex;
      align-items: center;
      li {
        margin-right: 20px;

        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

@media (min-width: 980px) {
  .header {
    padding: 32px 0;
    position: absolute;
    width: 100%;
    background-color: #fff;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__left {
      .logo {
        img {
          display: block;
          width: 190px;
        }
      }
    }

    &__burger {
      display: none;
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nav {
        margin-left: 100px;
        display: block;
        ul {
          display: flex;
          align-items: center;
          li {
            &:not(:last-child) {
              margin-right: 48px;
            }
            a {
              color: #192c50;
              font-size: 16px;
            }
          }
        }
      }
    }
    &__action {
      margin-left: 135px;
      ul {
        display: flex;
        align-items: center;
        li {
          &:not(:last-child) {
            margin-right: 20px;
          }
          img {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}
