.hero {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  &__right {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    img {
      object-fit: contain;
    }
    .select-model {
      position: absolute;
      z-index: 0;
      top: 85%;
      left: 10px;
      display: inline-block;
      padding: 18px 40px;
      background-color: #bf1b1b;
      color: #fff;
      font-family: "Noto Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      border-radius: 10px;
    }
  }

  &__text {
    height: 640px;
    overflow: hidden;
    padding-top: 132px;
    h1 {
      color: #192c50;
      font-size: 36px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 42px;
      text-transform: uppercase;
      span {
        color: #bf1b1b;
        display: block;
      }
    }
    p {
      margin-top: 32px;
      font-size: 20px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #192c50;
      max-width: 282px;
    }
  }
  &__collection {
    margin-top: 100px;
    h2 {
      font-size: 24px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      color: #192c50;
      margin-bottom: 20px;
    }
  }
}

.collection {
  &__list {
    display: flex;
    align-items: center;

    margin-top: 30px;
  }
  &__item {
    position: relative;
    transition: all 0.3s ease;
    &:hover {
      .collection__text {
        visibility: visible;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        position: absolute;
        bottom: 7px;
      }
    }
    &:not(:last-child) {
      margin-right: 30px;
    }
    a {
      position: relative;
      img {
        border-radius: 15px;
      }
    }
  }
  &__text {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 0;
    color: #fff;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 16px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 980px) {
  .hero {
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: unset;
    }

    &__right {
      position: unset;
      z-index: unset;
      top: unset;

      .select-model {
        position: absolute;
        z-index: 0;
        top: 25.5%;
        left: 50%;
        display: inline-block;
        padding: 18px 40px;
        background-color: #bf1b1b;
        color: #fff;
        font-family: "Noto Sans", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        border-radius: 10px;
      }
    }

    &__text {
      padding-top: 185px;
      height: unset;
      h1 {
        color: #192c50;
        font-size: 48px;
        font-family: "Noto Sans", sans-serif;
        font-weight: 800;
        line-height: 56px;
        text-transform: uppercase;
        span {
          color: #bf1b1b;
          display: block;
        }
      }
      p {
        margin-top: 32px;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        line-height: 26px;
        color: #192c50;
        max-width: 100%;
      }
    }
    &__collection {
      margin-top: 130px;
      h2 {
        font-size: 30px;
        font-family: "Noto Sans", sans-serif;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        color: #192c50;
        margin-bottom: 0px;
      }
    }
  }

  .collection {
    &__list {
      display: flex;
      align-items: center;
      margin-top: 30px;
    }
    &__item {
      position: relative;
      &:not(:last-child) {
        margin-right: 30px;
      }
      a {
        position: relative;
        img {
          border-radius: 15px;
        }
      }
    }
    &__text {
      position: absolute;
      bottom: 0;
      color: #fff;
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;
      font-weight: 500;
      line-height: 18px;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
