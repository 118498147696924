html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  width: 100%;
  height: 100%;
  font-family: "Noto Sans", sans-serif;
  scroll-behavior: smooth;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  letter-spacing: 0.02em;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
ul {
  padding: 0;
  margin: 0;
}

picture {
  display: block;
  line-height: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  max-width: var(--container-width);
  @media (max-width: 979px) {
    padding: 0 var(--container-offset);
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 28px;
  height: 40px;
  font-size: 13px;
  font-family: "MontserratMedium", sans-serif;
  border-radius: 5px;
  border: 0;
  transition: all 0.3s ease;
}

.btn-dark {
  background-color: #1e202b;
  color: #fff;
  &:hover {
    background-color: #38487c;
  }
}

.peach {
  background-color: #fff2ec;
}

.lightgreen {
  background-color: #e5fff2;
}

.purplelight {
  background-color: #f8edfe;
}

.bluelight {
  background-color: #e6f2fe;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #1e202b;
}

.fixed-scroll {
  display: none;
  position: fixed;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #38487c;
  width: 60px;
  height: 120px;
  border-radius: 20px;
}

.cookie {
  position: fixed;
  bottom: 33px;
  z-index: 9999;
  width: 100%;
  padding: 0 20px;
  line-height: 100%;
  display: none;
}

.cookie-block {
  background: #fff;
  max-width: 824px;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 18px 30px;
  border-radius: 15px;
  box-shadow: 10px 10px 40px rgb(150 156 187 / 12%);
  @media (max-width: 767px) {
    max-width: 100%;
    flex-direction: column;
    padding: 15px;
  }

  p {
    font-size: 12px;
    font-family: "MontserratMedium", sans-serif;
    margin: 0 30px;
    @media (max-width: 767px) {
      margin: 15px;
    }
  }
  button {
    background-color: #38487c;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
    white-space: nowrap;
    padding: 14px 30px;
    border: 0;
    border-radius: 5px;
    position: relative;
    z-index: 100;
    margin: 0 30px;
    @media (max-width: 767px) {
      margin: 15px;
    }
  }
}

.header--fixed {
  position: fixed !important;
  z-index: 999;
  width: 100%;
  box-shadow: 0 1px 0 #f1f3f9;
}

.btn-call {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #375bf4;
  color: #fff;
  font-family: "MontserratMedium", sans-serif;
  font-weight: 500;
  font-size: 13px;
  white-space: nowrap;
  padding: 14px 30px;
  position: relative;
  z-index: 100;
  border: 0;
  border-radius: 7px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #3430fe;
  }
}

.phone {
  font-size: 16px !important;
  color: #1e202b;
}

$fuschia: rgba(55, 91, 244, 0.5);
$button-bg: #375bf4;
$button-text-color: #fff;
$baby-blue: rgba(55, 91, 244, 0.5);

.btn-animate {
  display: block;
  padding: 1em 2em;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg;
  color: $button-text-color;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;

  &:focus {
    outline: 0;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }

  &:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, $button-bg 20%, transparent 30%),
      radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
    //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }

  &:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%),
      radial-gradient(circle, $button-bg 20%, transparent 20%), radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
    //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  &:active {
    transform: scale(1);
    background-color: darken($button-bg, 5%);
  }

  &.animate {
    &:before {
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after {
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.scrolldown {
  position: absolute;
  bottom: 25px;
  color: #fff;
  z-index: 5;
  cursor: pointer;
  svg {
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(60px);
  }
}

svg #wheel {
  animation: scroll ease 2s infinite;
  opacity: 0.5;
}

.forms {
  &__inline {
    margin-bottom: 10px;
    position: relative;
    button {
      margin-top: 15px;
    }
    input {
      display: inline-block;
      width: 100%;
      height: calc(3.5rem);
      line-height: 1.25;
      padding: 1rem 0.75rem;
      border-radius: 7px;
      outline: none;
      background-clip: padding-box;
      appearance: none;
      border: 0;
      &:focus + label {
        transform: translate3d(0, -8px, 0) scale(0.75);
        font-size: 14px;
      }
    }
    label {
      display: block;
      margin-bottom: 0.25rem;
      position: absolute;
      top: 5px;
      left: 0;
      height: 100%;
      padding: 1rem 0.75rem;
      pointer-events: none;
      transform-origin: 0 0;
      color: #1e202b;
      font-size: 14px;
      opacity: 0;
    }

    input:focus,
    input:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label {
      opacity: 0.75;
      transform: scale(0.85) translateY(-0.65rem) translateX(0.15rem);
    }
  }
}

::placeholder {
  display: block;
  color: #1e202b;
  font-weight: 500;
  padding: 10px 0;
  font-size: 14px;
  opacity: 0.85;
}

.select {
  position: relative;
}
.select.is-active .select__body {
  display: block;
}

.select__header {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.select__current {
  font-size: 18px;
  padding: 8px;
}
.select__icon {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  height: 40px;
  margin-left: auto;
  text-align: center;
  width: 40px;
}

.select__body {
  border: 1px solid #cccccc;
  background-color: #fff;
  display: none;
  border: 1px solid #e0e0e0;

  left: 0;
  position: absolute;
  right: 0;
  top: 55px;
  border-radius: 10px;
  padding: 24px;
  width: max-content;
  z-index: 99;
  .select__all {
    color: #aaaaaa;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding: 16px 0;
    display: block;
    cursor: pointer;
  }
  button {
    color: #bf1b1b;
    background: none;
    border: 0;
    margin-top: 16px;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    cursor: pointer;
  }
}
.select__item {
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  padding: 8px;
  span {
    display: block;
    margin-left: 0px;
    font-size: 14px;
  }
}

.select__item:hover {
}

.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #192c50;
  border-radius: 5px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
}

.checkbox:before {
  content: "";
  background-color: #192c50;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 8px;
  height: 8px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.checkbox:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox-label {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.circle-color {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  margin-right: 4px;
}

hr {
  border-top: 1px solid #aaa;
}

.filters__box {
  padding: 0;
  width: 215px;
  &:first-child {
    padding-top: 0;
  }
  span {
    display: block;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 100%;
    color: #bf1b1b !important;
  }
  label {
    display: flex;
    align-items: center;
    margin-top: 15px;
    font-size: 14px;
    input {
      margin-right: 10px;
    }
  }
  &--price {
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    .separator {
      width: 20px;
      display: flex;
      font-size: 19px;
      align-items: center;
      justify-content: center;
    }
    input {
      width: 100%;
      height: 30px;
      outline: none;
      font-size: 14px;
      border-radius: 5px;
      padding-left: 10px;
      border: 1px #eff0f0 solid;
      -moz-appearance: textfield;
      color: #abb2bf;
      border-radius: 10px;
      background-color: #f7f7f9;
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.12px;
      &::placeholder {
        color: #abb2bf;
      }
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 5px solid #bf1b1b;
    background: #fff;
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }
  input[type="range"]::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border: 5px solid #bf1b1b;
    border-radius: 50%;
    background: #fff;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }

  .slider-price {
    height: 5px;
    position: relative;
    background: #ebecee;
    border-radius: 5px;
    .progress {
      height: 100%;
      left: 25%;
      right: 25%;
      position: absolute;
      border-radius: 5px;
      background: #bf1b1b;
    }
  }
  .range-input {
    position: relative;
    input {
      position: absolute;
      width: 100%;
      height: 4px;
      top: -5px;
      background: none;
      pointer-events: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 10px;
    }
  }
}

@media (min-width: 980px) {
  .modals {
    .modal--addcart {
      max-width: 310px !important;
      border-radius: 15px !important;
      .card__action button {
        padding: 13px 15px;
      }
      .card__action {
        button {
          width: 100% !important;
        }
      }
      .size {
        margin-bottom: 32px;

        span {
          font-size: 16px !important;
          font-style: normal;
          font-weight: 500 !important;
          line-height: 20px;
          color: #192c50 !important;
          margin-bottom: 16px !important;
        }
        .size__list {
          gap: 16px !important;
        }
        .size__list div {
          border: 0;
          padding: 0;
          width: 18px;
          height: 18px;
          font-size: 16px !important;
        }
      }
      .color {
        margin-bottom: 40px;
        span {
          font-size: 16px !important;
          font-style: normal;
          font-weight: 500 !important;
          line-height: 20px;
          color: #192c50 !important;
          margin-bottom: 16px !important;
          margin-top: 16px;
        }
        .color__list div {
          width: 14px !important;
          height: 14px !important;
        }
      }
    }
  }

  .modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    display: none;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;
    .modal {
      background-color: #fff;
      width: max-content;
      max-width: 980px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;
      border-radius: 3px;

      .card {
        margin: 0;
        .card__top {
          height: auto;
        }

        .title {
          font-size: 24px;
          margin-bottom: 10px;
        }

        .vendor {
          font-size: 12px;
        }

        .color span {
          font-size: 12px;
          margin-bottom: 10px;
        }

        .color__list {
          margin-bottom: 22px;
        }

        .color__list div {
          width: 24px;
          height: 24px;
        }

        .tablesize {
          padding-top: 12px;
          padding-bottom: 18px;
        }

        .price {
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .size__list {
          gap: 9px;
        }

        .size span {
          font-size: 12px;
          margin-bottom: 5px;
        }

        .tablesize a {
          font-size: 12px;
        }

        .size__list div {
          font-size: 15px;
        }

        .color span b {
          font-size: 12px;
          margin-bottom: 10px;
        }

        .card__slider {
          height: 500px;
        }
        .card__info {
          width: 50%;
          padding-right: 62px;
        }

        .card__action button {
          width: 400px;
        }

        .card__slider {
          width: 50%;
        }
        .card__info {
          padding-right: 0;
        }
        .card__top .slider-nav {
          margin-right: 0;
        }
      }

      &__header {
        display: flex;
        justify-content: flex-end;
      }

      &__close {
        cursor: pointer;
        position: relative;
      }
      &__title {
        margin: 0;
        font-size: 22px;
        font-weight: 500;
        color: #1e1e1e;
      }
      &__wrap {
        padding: 26px;
      }
      &__form {
        margin-top: 22px;
        margin-bottom: 22px;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-item {
          display: block;
          width: 100%;
          margin-bottom: 12px;
          height: 42px;
          border: 1px solid #e0e0e0;
          border-radius: 27px;
          padding-left: 26px;
          background: #fff;
          outline: none;
          font-weight: 400;
          font-size: 14px;
          [type="text"] {
            color: #818992;
            font-weight: 400;
            font-size: 14px;
          }
        }
        &-btn {
          border: none;
          background-color: transparent;
          cursor: pointer;
          padding: 0;
          color: #fff;
          background-color: #2da4e1;
          height: 42px;
          width: 155px;
          border-radius: 27px;
          font-size: 16px;
          font-weight: 400;
        }
      }
      &__check {
        font-size: 12px;
        line-height: 1.5;
        color: #1e1e1e;
        margin: 0;
        font-weight: 400;
      }
    }
  }
}

.modal-overlay--visible {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.modal--visible {
  display: block !important;
}

.of-hidden {
  overflow: hidden;
}
