.catalog {
  padding-top: 85px;

  h2 {
    color: #192c50;
    font-size: 28px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;

    span {
      display: block;
      color: #bf1b1b;
    }
  }
}
.filter {
  margin-top: 50px;
  margin-bottom: 35px;
  position: relative;
  background-color: #f2f2f2;
  padding: 20px 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-reset {
    display: none;
  }

  .search-mob {
    display: flex;
    padding: 6px 8px;
    border-radius: 10px;
    border: 0.5px solid var(--gray-5, #e0e0e0);
    cursor: pointer;
    input {
      display: none;
      margin-right: 10px;
      border: 0;
      outline: 0;
      ::placeholder {
        color: #828282;
        font-family: "Noto Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }
    .hiddens {
      display: block;
    }
  }

  .f-mob {
    display: block;
    padding: 6px 8px;
    border-radius: 10px;
    border: 0.5px solid var(--gray-5, #e0e0e0);
    cursor: pointer;
  }

  &__list {
    display: none;
    flex-wrap: wrap;
    gap: 16px;
  }
  &__item {
    background: #fff;
    border-radius: 10px;
    padding: 10px 12px;
    display: inline-flex;
    align-items: center;

    span {
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #4f4f4f;
    }
    img {
      position: relative;
      top: 1px;
      margin-left: 4px;
    }
  }
}
.sort {
  margin-top: 34px;
  margin-bottom: 14px;

  .search {
    display: none;
  }

  &__item {
    background: #fff;
    border-radius: 10px;
    padding: 6px 12px;
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    height: 40px;
    &:last-child {
      display: none;
    }
    input {
      margin-right: 8px;
    }
    span {
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #4f4f4f;
    }
    img {
      position: relative;
      top: 1px;
      margin-left: 4px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
  }
  &__right {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
  }
  &__left {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}

@media (min-width: 980px) {
  .catalog {
    padding-top: 85px;
    h2 {
      color: #192c50;
      font-size: 48px;
      font-family: "Noto Sans", sans-serif;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;

      span {
        display: block;
        color: #bf1b1b;
      }
    }
  }
  .filter {
    margin-top: 50px;
    margin-bottom: 35px;
    position: relative;
    background-color: #f2f2f2;
    padding: 20px 0;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .filter-reset {
      display: inline-block;
      color: #aaa;
      font-family: "Noto Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      cursor: pointer;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    .search-mob {
      display: none;
    }

    .f-mob {
      display: none;
    }

    &__item {
      background: #fff;
      border-radius: 10px;
      padding: 10px 12px;
      display: inline-flex;
      align-items: center;

      span {
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        color: #4f4f4f;
      }
      img {
        position: relative;
        top: 1px;
        margin-left: 4px;
      }
    }
  }
  .sort {
    margin-top: 34px;
    margin-bottom: 14px;
    &__search {
      padding: 6px 8px;
      border-radius: 10px;
      border: 0.5px solid var(--gray-5, #e0e0e0);
      cursor: pointer;
      img {
        display: block;
      }
    }

    .search {
      display: flex;
      input {
        display: none;
        margin-right: 10px;
        border: 0;
        outline: 0;
        ::placeholder {
          color: #828282;
          font-family: "Noto Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
        }
      }
      .hidden {
        display: block;
      }
    }

    &__item {
      background: #fff;
      border-radius: 10px;
      padding: 10px 16px;
      display: inline-flex;
      align-items: center;
      border-radius: 10px;
      border: 1px solid #e0e0e0;
      height: 40px;
      &:last-child {
        display: inline-block;
      }
      input {
        margin-right: 8px;
      }
      span {
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        color: #4f4f4f;
      }
      img {
        position: relative;
        top: 1px;
        margin-left: 4px;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;
    }
    &__right {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      align-items: center;
    }
    &__left {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}
