.banner {
  margin-bottom: 80px;

  &__bg {
    background-image: url("../img/banner-mobile.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;

    padding: 24px;
    border-radius: 15px;
    height: 463px;
    h2 {
      font-size: 36px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 800;
      line-height: 50px;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 18px;
    }
    p {
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #fff;
      margin-bottom: 18px;
      br {
        display: none;
      }
    }
    a {
      display: inline-flex;
      padding: 10px 28px;
      background-color: #bf1b1b;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      border-radius: 10px;
      margin-top: 200px;
    }
  }
}

@media (min-width: 980px) {
  .banner {
    margin-bottom: 80px;
    &__bg {
      background-image: url("../img/banner.png");
      padding: 36px 45px;
      border-radius: 15px;
      background-size: unset;
      background-position: unset;
      background-repeat: no-repeat;
      height: unset;
      h2 {
        font-size: 48px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 800;
        line-height: 50px;
        text-transform: uppercase;
        color: #192c50;
        margin-bottom: 18px;
      }
      p {
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: #151515;
        margin-bottom: 18px;
        br {
          display: block;
        }
      }
      a {
        display: inline-flex;
        padding: 20px 58px;
        background-color: #bf1b1b;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        border-radius: 10px;
        margin-top: unset;
      }
    }
  }
}

@media (min-width: 980px) {
  .viewedat {
    margin-bottom: 160px;
    h2 {
      font-size: 24px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      text-transform: uppercase;
      color: #192c50;
      margin-bottom: 30px;
    }
    .products__item--text {
      text-align: center;
    }
  }
}
