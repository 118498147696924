@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;800&display=swap");
:root {
  --font-family: "Open Sans", sans-serif;
  --content-width: 1110px;
  --container-offset: 10px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
}

/* stylelint-disable */ /* stylelint-disable */
.burger-close {
  position: absolute;
  right: var(--container-offset);
  top: 30px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  width: 44px;
  height: 44px;
  background-image: url("../img/close.svg");
  background-position: center;
  background-size: 14px 12px;
  background-repeat: no-repeat;
}

/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  width: 100%;
  height: 100%;
  font-family: "Noto Sans", sans-serif;
  scroll-behavior: smooth;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  letter-spacing: 0.02em;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
ul {
  padding: 0;
  margin: 0;
}

picture {
  display: block;
  line-height: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  max-width: var(--container-width);
}
@media (max-width: 979px) {
  .container {
    padding: 0 var(--container-offset);
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 28px;
  height: 40px;
  font-size: 13px;
  font-family: "MontserratMedium", sans-serif;
  border-radius: 5px;
  border: 0;
  transition: all 0.3s ease;
}

.btn-dark {
  background-color: #1e202b;
  color: #fff;
}
.btn-dark:hover {
  background-color: #38487c;
}

.peach {
  background-color: #fff2ec;
}

.lightgreen {
  background-color: #e5fff2;
}

.purplelight {
  background-color: #f8edfe;
}

.bluelight {
  background-color: #e6f2fe;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #1e202b;
}

.fixed-scroll {
  display: none;
  position: fixed;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #38487c;
  width: 60px;
  height: 120px;
  border-radius: 20px;
}

.cookie {
  position: fixed;
  bottom: 33px;
  z-index: 9999;
  width: 100%;
  padding: 0 20px;
  line-height: 100%;
  display: none;
}

.cookie-block {
  background: #fff;
  max-width: 824px;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 18px 30px;
  border-radius: 15px;
  box-shadow: 10px 10px 40px rgba(150, 156, 187, 0.12);
}
@media (max-width: 767px) {
  .cookie-block {
    max-width: 100%;
    flex-direction: column;
    padding: 15px;
  }
}
.cookie-block p {
  font-size: 12px;
  font-family: "MontserratMedium", sans-serif;
  margin: 0 30px;
}
@media (max-width: 767px) {
  .cookie-block p {
    margin: 15px;
  }
}
.cookie-block button {
  background-color: #38487c;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  padding: 14px 30px;
  border: 0;
  border-radius: 5px;
  position: relative;
  z-index: 100;
  margin: 0 30px;
}
@media (max-width: 767px) {
  .cookie-block button {
    margin: 15px;
  }
}

.header--fixed {
  position: fixed !important;
  z-index: 999;
  width: 100%;
  box-shadow: 0 1px 0 #f1f3f9;
}

.btn-call {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #375bf4;
  color: #fff;
  font-family: "MontserratMedium", sans-serif;
  font-weight: 500;
  font-size: 13px;
  white-space: nowrap;
  padding: 14px 30px;
  position: relative;
  z-index: 100;
  border: 0;
  border-radius: 7px;
  color: #fff;
  cursor: pointer;
}
.btn-call:hover {
  background-color: #3430fe;
}

.phone {
  font-size: 16px !important;
  color: #1e202b;
}

.btn-animate {
  display: block;
  padding: 1em 2em;
  -webkit-appearance: none;
  appearance: none;
  background-color: #375bf4;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
}
.btn-animate:focus {
  outline: 0;
}
.btn-animate:before, .btn-animate:after {
  position: absolute;
  content: "";
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}
.btn-animate:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, transparent 20%, #375bf4 20%, transparent 30%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, transparent 10%, #375bf4 15%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}
.btn-animate:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, transparent 10%, #375bf4 15%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%), radial-gradient(circle, #375bf4 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}
.btn-animate:active {
  transform: scale(1);
  background-color: #1f47f3;
}
.btn-animate.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}
.btn-animate.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
.scrolldown {
  position: absolute;
  bottom: 25px;
  color: #fff;
  z-index: 5;
  cursor: pointer;
}
.scrolldown svg {
  opacity: 1;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(60px);
  }
}
svg #wheel {
  animation: scroll ease 2s infinite;
  opacity: 0.5;
}

.forms__inline {
  margin-bottom: 10px;
  position: relative;
}
.forms__inline button {
  margin-top: 15px;
}
.forms__inline input {
  display: inline-block;
  width: 100%;
  height: 3.5rem;
  line-height: 1.25;
  padding: 1rem 0.75rem;
  border-radius: 7px;
  outline: none;
  background-clip: padding-box;
  appearance: none;
  border: 0;
}
.forms__inline input:focus + label {
  transform: translate3d(0, -8px, 0) scale(0.75);
  font-size: 14px;
}
.forms__inline label {
  display: block;
  margin-bottom: 0.25rem;
  position: absolute;
  top: 5px;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  transform-origin: 0 0;
  color: #1e202b;
  font-size: 14px;
  opacity: 0;
}
.forms__inline input:focus,
.forms__inline input:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.forms__inline input:focus ~ label,
.forms__inline input:not(:placeholder-shown) ~ label {
  opacity: 0.75;
  transform: scale(0.85) translateY(-0.65rem) translateX(0.15rem);
}

::placeholder {
  display: block;
  color: #1e202b;
  font-weight: 500;
  padding: 10px 0;
  font-size: 14px;
  opacity: 0.85;
}

.select {
  position: relative;
}

.select.is-active .select__body {
  display: block;
}

.select__header {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.select__current {
  font-size: 18px;
  padding: 8px;
}

.select__icon {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  height: 40px;
  margin-left: auto;
  text-align: center;
  width: 40px;
}

.select__body {
  border: 1px solid #cccccc;
  background-color: #fff;
  display: none;
  border: 1px solid #e0e0e0;
  left: 0;
  position: absolute;
  right: 0;
  top: 55px;
  border-radius: 10px;
  padding: 24px;
  width: max-content;
  z-index: 99;
}
.select__body .select__all {
  color: #aaaaaa;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  padding: 16px 0;
  display: block;
  cursor: pointer;
}
.select__body button {
  color: #bf1b1b;
  background: none;
  border: 0;
  margin-top: 16px;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;
}

.select__item {
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  padding: 8px;
}
.select__item span {
  display: block;
  margin-left: 0px;
  font-size: 14px;
}

.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #192c50;
  border-radius: 5px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
}

.checkbox:before {
  content: "";
  background-color: #192c50;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 8px;
  height: 8px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.checkbox:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox-label {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.circle-color {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  margin-right: 4px;
}

hr {
  border-top: 1px solid #aaa;
}

.filters__box {
  padding: 0;
  width: 215px;
}
.filters__box:first-child {
  padding-top: 0;
}
.filters__box span {
  display: block;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 100%;
  color: #bf1b1b !important;
}
.filters__box label {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;
}
.filters__box label input {
  margin-right: 10px;
}
.filters__box--price {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.filters__box--price .separator {
  width: 20px;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
}
.filters__box--price input {
  width: 100%;
  height: 30px;
  outline: none;
  font-size: 14px;
  border-radius: 5px;
  padding-left: 10px;
  border: 1px #eff0f0 solid;
  -moz-appearance: textfield;
  color: #abb2bf;
  border-radius: 10px;
  background-color: #f7f7f9;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.12px;
}
.filters__box--price input::placeholder {
  color: #abb2bf;
}
.filters__box input[type=number]::-webkit-outer-spin-button,
.filters__box input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.filters__box input[type=range]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid #bf1b1b;
  background: #fff;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
.filters__box input[type=range]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border: 5px solid #bf1b1b;
  border-radius: 50%;
  background: #fff;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
.filters__box .slider-price {
  height: 5px;
  position: relative;
  background: #ebecee;
  border-radius: 5px;
}
.filters__box .slider-price .progress {
  height: 100%;
  left: 25%;
  right: 25%;
  position: absolute;
  border-radius: 5px;
  background: #bf1b1b;
}
.filters__box .range-input {
  position: relative;
}
.filters__box .range-input input {
  position: absolute;
  width: 100%;
  height: 4px;
  top: -5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 10px;
}

@media (min-width: 980px) {
  .modals .modal--addcart {
    max-width: 310px !important;
    border-radius: 15px !important;
  }
  .modals .modal--addcart .card__action button {
    padding: 13px 15px;
  }
  .modals .modal--addcart .card__action button {
    width: 100% !important;
  }
  .modals .modal--addcart .size {
    margin-bottom: 32px;
  }
  .modals .modal--addcart .size span {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    color: #192c50 !important;
    margin-bottom: 16px !important;
  }
  .modals .modal--addcart .size .size__list {
    gap: 16px !important;
  }
  .modals .modal--addcart .size .size__list div {
    border: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    font-size: 16px !important;
  }
  .modals .modal--addcart .color {
    margin-bottom: 40px;
  }
  .modals .modal--addcart .color span {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
    color: #192c50 !important;
    margin-bottom: 16px !important;
    margin-top: 16px;
  }
  .modals .modal--addcart .color .color__list div {
    width: 14px !important;
    height: 14px !important;
  }
  .modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    display: none;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
  .modal-overlay .modal {
    background-color: #fff;
    width: max-content;
    max-width: 980px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    border-radius: 3px;
  }
  .modal-overlay .modal .card {
    margin: 0;
  }
  .modal-overlay .modal .card .card__top {
    height: auto;
  }
  .modal-overlay .modal .card .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .modal-overlay .modal .card .vendor {
    font-size: 12px;
  }
  .modal-overlay .modal .card .color span {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .modal-overlay .modal .card .color__list {
    margin-bottom: 22px;
  }
  .modal-overlay .modal .card .color__list div {
    width: 24px;
    height: 24px;
  }
  .modal-overlay .modal .card .tablesize {
    padding-top: 12px;
    padding-bottom: 18px;
  }
  .modal-overlay .modal .card .price {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .modal-overlay .modal .card .size__list {
    gap: 9px;
  }
  .modal-overlay .modal .card .size span {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .modal-overlay .modal .card .tablesize a {
    font-size: 12px;
  }
  .modal-overlay .modal .card .size__list div {
    font-size: 15px;
  }
  .modal-overlay .modal .card .color span b {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .modal-overlay .modal .card .card__slider {
    height: 500px;
  }
  .modal-overlay .modal .card .card__info {
    width: 50%;
    padding-right: 62px;
  }
  .modal-overlay .modal .card .card__action button {
    width: 400px;
  }
  .modal-overlay .modal .card .card__slider {
    width: 50%;
  }
  .modal-overlay .modal .card .card__info {
    padding-right: 0;
  }
  .modal-overlay .modal .card .card__top .slider-nav {
    margin-right: 0;
  }
  .modal-overlay .modal__header {
    display: flex;
    justify-content: flex-end;
  }
  .modal-overlay .modal__close {
    cursor: pointer;
    position: relative;
  }
  .modal-overlay .modal__title {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    color: #1e1e1e;
  }
  .modal-overlay .modal__wrap {
    padding: 26px;
  }
  .modal-overlay .modal__form {
    margin-top: 22px;
    margin-bottom: 22px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal-overlay .modal__form-item {
    display: block;
    width: 100%;
    margin-bottom: 12px;
    height: 42px;
    border: 1px solid #e0e0e0;
    border-radius: 27px;
    padding-left: 26px;
    background: #fff;
    outline: none;
    font-weight: 400;
    font-size: 14px;
  }
  .modal-overlay .modal__form-item [type=text] {
    color: #818992;
    font-weight: 400;
    font-size: 14px;
  }
  .modal-overlay .modal__form-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    color: #fff;
    background-color: #2da4e1;
    height: 42px;
    width: 155px;
    border-radius: 27px;
    font-size: 16px;
    font-weight: 400;
  }
  .modal-overlay .modal__check {
    font-size: 12px;
    line-height: 1.5;
    color: #1e1e1e;
    margin: 0;
    font-weight: 400;
  }
}
.modal-overlay--visible {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.modal--visible {
  display: block !important;
}

.of-hidden {
  overflow: hidden;
}

.header {
  padding: 10px;
  position: absolute;
  width: 100%;
  background-color: #fff;
}
.header .burger {
  border-radius: 7px;
  background: #efefef;
  padding: 5px;
}
.header .burger img {
  width: 24px;
  height: 24px;
}
.header__left .logo img {
  display: block;
  width: 140px;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__right .nav {
  margin-left: 100px;
  display: none;
}
.header__right .nav ul {
  display: flex;
  align-items: center;
}
.header__right .nav ul li:not(:last-child) {
  margin-right: 48px;
}
.header__right .nav ul li a {
  color: #192c50;
  font-size: 16px;
}
.header__action {
  margin-left: 0px;
}
.header__action ul {
  display: flex;
  align-items: center;
}
.header__action ul li {
  margin-right: 20px;
}
.header__action ul li img {
  width: 28px;
  height: 28px;
}

@media (min-width: 980px) {
  .header {
    padding: 32px 0;
    position: absolute;
    width: 100%;
    background-color: #fff;
  }
  .header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header__left .logo img {
    display: block;
    width: 190px;
  }
  .header__burger {
    display: none;
  }
  .header__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header__right .nav {
    margin-left: 100px;
    display: block;
  }
  .header__right .nav ul {
    display: flex;
    align-items: center;
  }
  .header__right .nav ul li:not(:last-child) {
    margin-right: 48px;
  }
  .header__right .nav ul li a {
    color: #192c50;
    font-size: 16px;
  }
  .header__action {
    margin-left: 135px;
  }
  .header__action ul {
    display: flex;
    align-items: center;
  }
  .header__action ul li:not(:last-child) {
    margin-right: 20px;
  }
  .header__action ul li img {
    width: auto;
    height: auto;
  }
}
.hero__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.hero__right {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.hero__right img {
  object-fit: contain;
}
.hero__right .select-model {
  position: absolute;
  z-index: 0;
  top: 85%;
  left: 10px;
  display: inline-block;
  padding: 18px 40px;
  background-color: #bf1b1b;
  color: #fff;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border-radius: 10px;
}
.hero__text {
  height: 640px;
  overflow: hidden;
  padding-top: 132px;
}
.hero__text h1 {
  color: #192c50;
  font-size: 36px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  text-transform: uppercase;
}
.hero__text h1 span {
  color: #bf1b1b;
  display: block;
}
.hero__text p {
  margin-top: 32px;
  font-size: 20px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #192c50;
  max-width: 282px;
}
.hero__collection {
  margin-top: 100px;
}
.hero__collection h2 {
  font-size: 24px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  color: #192c50;
  margin-bottom: 20px;
}

.collection__list {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.collection__item {
  position: relative;
  transition: all 0.3s ease;
}
.collection__item:hover .collection__text {
  visibility: visible;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  position: absolute;
  bottom: 7px;
}
.collection__item:not(:last-child) {
  margin-right: 30px;
}
.collection__item a {
  position: relative;
}
.collection__item a img {
  border-radius: 15px;
}
.collection__text {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  position: absolute;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 980px) {
  .hero__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: unset;
  }
  .hero__right {
    position: unset;
    z-index: unset;
    top: unset;
  }
  .hero__right .select-model {
    position: absolute;
    z-index: 0;
    top: 25.5%;
    left: 50%;
    display: inline-block;
    padding: 18px 40px;
    background-color: #bf1b1b;
    color: #fff;
    font-family: "Noto Sans", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    border-radius: 10px;
  }
  .hero__text {
    padding-top: 185px;
    height: unset;
  }
  .hero__text h1 {
    color: #192c50;
    font-size: 48px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 800;
    line-height: 56px;
    text-transform: uppercase;
  }
  .hero__text h1 span {
    color: #bf1b1b;
    display: block;
  }
  .hero__text p {
    margin-top: 32px;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    line-height: 26px;
    color: #192c50;
    max-width: 100%;
  }
  .hero__collection {
    margin-top: 130px;
  }
  .hero__collection h2 {
    font-size: 30px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    color: #192c50;
    margin-bottom: 0px;
  }
  .collection__list {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .collection__item {
    position: relative;
  }
  .collection__item:not(:last-child) {
    margin-right: 30px;
  }
  .collection__item a {
    position: relative;
  }
  .collection__item a img {
    border-radius: 15px;
  }
  .collection__text {
    position: absolute;
    bottom: 0;
    color: #fff;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.catalog {
  padding-top: 85px;
}
.catalog h2 {
  color: #192c50;
  font-size: 28px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
}
.catalog h2 span {
  display: block;
  color: #bf1b1b;
}

.filter {
  margin-top: 50px;
  margin-bottom: 35px;
  position: relative;
  background-color: #f2f2f2;
  padding: 20px 0;
}
.filter .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter .filter-reset {
  display: none;
}
.filter .search-mob {
  display: flex;
  padding: 6px 8px;
  border-radius: 10px;
  border: 0.5px solid var(--gray-5, #e0e0e0);
  cursor: pointer;
}
.filter .search-mob input {
  display: none;
  margin-right: 10px;
  border: 0;
  outline: 0;
}
.filter .search-mob input ::placeholder {
  color: #828282;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
.filter .search-mob .hiddens {
  display: block;
}
.filter .f-mob {
  display: block;
  padding: 6px 8px;
  border-radius: 10px;
  border: 0.5px solid var(--gray-5, #e0e0e0);
  cursor: pointer;
}
.filter__list {
  display: none;
  flex-wrap: wrap;
  gap: 16px;
}
.filter__item {
  background: #fff;
  border-radius: 10px;
  padding: 10px 12px;
  display: inline-flex;
  align-items: center;
}
.filter__item span {
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: #4f4f4f;
}
.filter__item img {
  position: relative;
  top: 1px;
  margin-left: 4px;
}

.sort {
  margin-top: 34px;
  margin-bottom: 14px;
}
.sort .search {
  display: none;
}
.sort__item {
  background: #fff;
  border-radius: 10px;
  padding: 6px 12px;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  height: 40px;
}
.sort__item:last-child {
  display: none;
}
.sort__item input {
  margin-right: 8px;
}
.sort__item span {
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: #4f4f4f;
}
.sort__item img {
  position: relative;
  top: 1px;
  margin-left: 4px;
}
.sort__list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
}
.sort__right {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}
.sort__left {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

@media (min-width: 980px) {
  .catalog {
    padding-top: 85px;
  }
  .catalog h2 {
    color: #192c50;
    font-size: 48px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }
  .catalog h2 span {
    display: block;
    color: #bf1b1b;
  }
  .filter {
    margin-top: 50px;
    margin-bottom: 35px;
    position: relative;
    background-color: #f2f2f2;
    padding: 20px 0;
  }
  .filter .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filter .filter-reset {
    display: inline-block;
    color: #aaa;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    cursor: pointer;
  }
  .filter__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .filter .search-mob {
    display: none;
  }
  .filter .f-mob {
    display: none;
  }
  .filter__item {
    background: #fff;
    border-radius: 10px;
    padding: 10px 12px;
    display: inline-flex;
    align-items: center;
  }
  .filter__item span {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #4f4f4f;
  }
  .filter__item img {
    position: relative;
    top: 1px;
    margin-left: 4px;
  }
  .sort {
    margin-top: 34px;
    margin-bottom: 14px;
  }
  .sort__search {
    padding: 6px 8px;
    border-radius: 10px;
    border: 0.5px solid var(--gray-5, #e0e0e0);
    cursor: pointer;
  }
  .sort__search img {
    display: block;
  }
  .sort .search {
    display: flex;
  }
  .sort .search input {
    display: none;
    margin-right: 10px;
    border: 0;
    outline: 0;
  }
  .sort .search input ::placeholder {
    color: #828282;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .sort .search .hidden {
    display: block;
  }
  .sort__item {
    background: #fff;
    border-radius: 10px;
    padding: 10px 16px;
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    height: 40px;
  }
  .sort__item:last-child {
    display: inline-block;
  }
  .sort__item input {
    margin-right: 8px;
  }
  .sort__item span {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #4f4f4f;
  }
  .sort__item img {
    position: relative;
    top: 1px;
    margin-left: 4px;
  }
  .sort__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
  }
  .sort__right {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
  }
  .sort__left {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}
.products__list {
  grid-column: 2span;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
}
.products__item {
  margin-bottom: 0px;
  border-radius: 15px;
  transition: 0.3s all;
  padding: 8px;
  transition: all 0.3s ease;
  position: relative;
}
.products__item--preview {
  opacity: 0;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 12px 0;
  overflow: hidden;
}
.products__item--preview a {
  font-size: 14px;
  color: #aaa;
  cursor: pointer;
}
.products__item:hover .products__item--preview {
  opacity: 1;
}
.products__item:hover .products__item--cart {
  visibility: visible;
  opacity: 1;
}
.products__item--big {
  grid-column: 2 span;
}
.products__item--big .products__item--text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.products__item--big .products__item--text span {
  margin: 0;
}
.products__item:hover {
  background: #fff;
  box-shadow: 0px 10px 17px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
.products__item--text {
  color: #192c50;
}
.products__item--text h3 {
  display: block;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-top: 12px;
}
.products__item--text span {
  display: block;
  font-size: 20px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
}
.products__item--text small {
  display: block;
  color: #aaaaaa;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 12px;
}
.products__item--cart {
  visibility: hidden;
  opacity: 0;
}
.products__item--add {
  display: block;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #bf1b1b;
  color: #fff;
  font-size: 12px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}
.products__pagination {
  margin-top: 50px;
  margin-bottom: 180px;
  display: flex;
  justify-content: center;
}
.products__showmore button {
  color: #bf1b1b;
  border: 1px solid #bf1b1b;
  padding: 16px 32px;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border-radius: 10px;
  transition: all 0.3s ease;
}
.products__showmore button:hover {
  background-color: #bf1b1b;
  color: #fff;
}

@media (min-width: 980px) {
  .products__list {
    grid-column: 4 span;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }
  .products__item {
    margin-bottom: 0px;
    border-radius: 15px;
    transition: 0.3s all;
    padding: 8px;
    transition: all 0.3s ease;
    position: relative;
  }
  .products__item:hover .color {
    visibility: visible;
    margin-top: 10px;
  }
  .products__item .color {
    visibility: hidden;
    margin-top: 0px;
  }
  .products__item .color__list {
    display: flex;
  }
  .products__item .color__list div {
    width: 16px;
    height: 16px;
    cursor: pointer;
    border-radius: 32px;
  }
  .products__item .color__list div:not(:last-child) {
    margin-right: 10px;
  }
  .products__item--preview {
    opacity: 0;
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 55%;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 12px 0;
    overflow: hidden;
  }
  .products__item--preview span {
    font-size: 14px;
    color: #aaa;
    cursor: pointer;
  }
  .products__item:hover .products__item--preview {
    opacity: 1;
  }
  .products__item:hover .products__item--cart {
    visibility: visible;
    opacity: 1;
  }
  .products__item--big {
    grid-column: 2 span;
  }
  .products__item--big .products__item--text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .products__item--big .products__item--text span {
    margin: 0;
  }
  .products__item:hover {
    background: #fff;
    box-shadow: 0px 10px 17px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
  .products__item--text {
    color: #192c50;
  }
  .products__item--text h3 {
    display: block;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 500;
    line-height: 20px;
    margin-top: 12px;
  }
  .products__item--text h3 a {
    color: #192c50;
  }
  .products__item--text span {
    display: block;
    font-size: 20px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .products__item--text small {
    display: block;
    color: #aaaaaa;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .products__item--cart {
    visibility: hidden;
    opacity: 0;
  }
  .products__item--add {
    display: block;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #bf1b1b;
    color: #fff;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 500;
    line-height: 20px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
  }
  .products__pagination {
    margin-top: 50px;
    margin-bottom: 180px;
    display: flex;
    justify-content: center;
  }
  .products__showmore button {
    color: #bf1b1b;
    border: 1px solid #bf1b1b;
    padding: 16px 32px;
    background-color: #fff;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  .products__showmore button:hover {
    background-color: #bf1b1b;
    color: #fff;
  }
}
.banner {
  margin-bottom: 80px;
}
.banner__bg {
  background-image: url("../img/banner-mobile.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 24px;
  border-radius: 15px;
  height: 463px;
}
.banner__bg h2 {
  font-size: 36px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 18px;
}
.banner__bg p {
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  margin-bottom: 18px;
}
.banner__bg p br {
  display: none;
}
.banner__bg a {
  display: inline-flex;
  padding: 10px 28px;
  background-color: #bf1b1b;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 10px;
  margin-top: 200px;
}

@media (min-width: 980px) {
  .banner {
    margin-bottom: 80px;
  }
  .banner__bg {
    background-image: url("../img/banner.png");
    padding: 36px 45px;
    border-radius: 15px;
    background-size: unset;
    background-position: unset;
    background-repeat: no-repeat;
    height: unset;
  }
  .banner__bg h2 {
    font-size: 48px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
    text-transform: uppercase;
    color: #192c50;
    margin-bottom: 18px;
  }
  .banner__bg p {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #151515;
    margin-bottom: 18px;
  }
  .banner__bg p br {
    display: block;
  }
  .banner__bg a {
    display: inline-flex;
    padding: 20px 58px;
    background-color: #bf1b1b;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    border-radius: 10px;
    margin-top: unset;
  }
}
@media (min-width: 980px) {
  .viewedat {
    margin-bottom: 160px;
  }
  .viewedat h2 {
    font-size: 24px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #192c50;
    margin-bottom: 30px;
  }
  .viewedat .products__item--text {
    text-align: center;
  }
}
@media (min-width: 980px) {
  .card {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  .card__top {
    display: flex;
    height: 630px;
  }
  .card__top .slider-nav {
    width: 160px;
    margin-right: 30px;
  }
  .card__top .slider-nav img {
    height: 160px;
  }
  .card__top .slider-block {
    overflow: hidden;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    border-radius: 14.111px;
  }
  .card__bottom {
    margin-top: 100px;
  }
  .card .title {
    font-size: 32px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    color: #192c50;
    margin-bottom: 10px;
  }
  .card .vendor {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #aaa;
  }
  .card__slider {
    width: 592px;
    height: 630px;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
  }
  .card__info {
    width: calc(100% - 592px - 60px);
    padding-right: 62px;
  }
  .card .price {
    margin-top: 60px;
    margin-bottom: 48px;
  }
  .card .price__current {
    color: #192c50;
    font-size: 48px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    margin-top: 10px;
  }
  .card .price__old {
    font-size: 24px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration: line-through;
    color: #dbdbdb;
  }
  .card .color span {
    display: block;
    color: #aaaaaa;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .card .color span b {
    color: #192c50;
    font-weight: 500;
    font-family: "Noto Sans", sans-serif;
  }
  .card .color__list {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 35px;
  }
  .card .color__list div {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    cursor: pointer;
  }
  .card .size span {
    display: block;
    color: #aaaaaa;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .card .size span b {
    color: #192c50;
    font-weight: 500;
    font-family: "Noto Sans", sans-serif;
  }
  .card .size__list {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .card .size__list div {
    display: flex;
    width: 50px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #f2f2f2;
  }
  .card .tablesize {
    display: block;
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .card .tablesize a {
    color: #aaaaaa;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .card__description {
    margin-bottom: 48px;
  }
  .card__description h3 {
    color: #192c50;
    font-size: 24px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .card__description p {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #4f4f4f;
    max-width: 980px;
  }
  .card__characteristics {
    margin-bottom: 48px;
  }
  .card__characteristics h3 {
    color: #192c50;
    font-size: 24px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .card__setka {
    margin-bottom: 48px;
  }
  .card__setka h3 {
    color: #192c50;
    font-size: 24px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .card__status {
    margin-bottom: 48px;
  }
  .card__status span {
    color: #192c50;
    font-size: 24px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .card .characteristics {
    width: 100%;
  }
  .card .characteristics ul li {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14px;
  }
  .card .characteristics ul li span {
    display: block;
  }
  .card .characteristics ul li .name {
    margin-right: -30%;
    overflow: hidden;
    vertical-align: top;
    width: 140px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .card .characteristics ul li .name:after {
    content: "";
    display: inline-block;
    width: 100%;
    margin: 0 -99% 0 3px;
    vertical-align: baseline;
    border-bottom: 1px dashed #fff;
  }
  .card .characteristics ul li .value {
    display: inline-block;
    letter-spacing: normal;
    padding-left: 3px;
    margin-left: 33%;
    width: 380px;
    vertical-align: bottom;
    color: #606060;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .card .setka {
    width: 100%;
  }
  .card .setka ul {
    max-width: 1040px;
  }
  .card .setka ul li {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14px;
  }
  .card .setka ul li span {
    display: block;
  }
  .card .setka ul li .name {
    margin-right: 0;
    overflow: hidden;
    vertical-align: top;
    width: 140px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .card .setka ul li .name:after {
    content: "";
    display: inline-block;
    width: 100%;
    margin: 0 -99% 0 3px;
    vertical-align: baseline;
    border-bottom: 1px dashed #fff;
  }
  .card .setka ul li .value {
    display: inline-block;
    letter-spacing: normal;
    padding-left: 3px;
    margin-left: 40px;
    width: 20px;
    vertical-align: bottom;
    color: #606060;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-right: 10px;
  }
  .card__action button {
    display: flex;
    width: 445px;
    padding: 16px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #bf1b1b;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
@media (min-width: 980px) {
  .breadcrumbs {
    margin-bottom: 37px;
  }
  .breadcrumbs ul {
    display: flex;
  }
  .breadcrumbs ul li {
    padding: 0px 10px;
    position: relative;
  }
  .breadcrumbs ul li:first-child {
    padding-left: 0;
  }
  .breadcrumbs ul li:first-child:after {
    content: "-";
    display: none;
  }
  .breadcrumbs ul li:after {
    content: "-";
    display: block;
    position: absolute;
    top: 0;
    left: -2px;
  }
  .breadcrumbs ul li.actives a {
    color: #192c50;
  }
  .breadcrumbs ul li a {
    color: #aaa;
    font-size: 12px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .textpage {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  .textpage__grid {
    display: flex;
    flex-wrap: wrap;
  }
  .textpage__content {
    padding-left: 23px;
  }
  .textpage__content h1 {
    color: #192c50;
    font-size: 48px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 24px;
  }
  .textpage__content p {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #192c50;
  }
  .textpage__content a {
    display: block;
    margin-top: 50px;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-decoration: underline;
    color: #aaa;
  }
  .textpage__sidebar {
    padding-right: 58px;
    border-right: 1px solid #dbdbdb;
    height: 285px;
  }
  .textpage__sidebar ul li {
    margin-bottom: 16px;
  }
  .textpage__sidebar ul li.active {
    display: flex;
    align-items: center;
  }
  .textpage__sidebar ul li.active:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: #bf1b1b;
    border-radius: 50%;
    margin-right: 8px;
  }
  .textpage__sidebar ul li.active a {
    color: #bf1b1b;
  }
  .textpage__sidebar ul li a {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #192c50;
  }
  .textpage__sidebar ul li a:hover {
    color: #bf1b1b;
  }
  .account {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  .account__grid {
    display: flex;
    flex-wrap: wrap;
  }
  .account .form {
    flex-direction: column;
    width: 734px;
  }
  .account .form__item {
    margin: 0;
  }
  .account .form__saler {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .account .form__saler input {
    width: 352px;
  }
  .account .form__saler button {
    width: 100%;
    display: flex;
    width: 722px;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background-color: #aaa;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .account .form .saler-title {
    font-size: 24px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .account .form__line {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 56px;
  }
  .account .form__line input {
    width: 352px;
  }
  .account .form .edit {
    display: inline-block;
    margin-top: 35px;
    width: 180px;
    cursor: pointer;
  }
  .account .form .edit img {
    transform: rotate(180deg);
    position: relative;
    top: -2px;
    left: 5px;
  }
  .account .form .edit.act img {
    transform: rotate(0deg);
  }
  .account .form__password {
    display: none;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 56px;
  }
  .account .form__password.activeblock {
    display: flex;
  }
  .account .form__password input {
    width: 352px;
  }
  .account .form__password button {
    width: 352px;
    display: block;
    background-color: #aaa;
    color: #fff;
    position: relative;
    top: 26px;
  }
  .account__info {
    padding-left: 23px;
  }
  .account__info h1 {
    color: #192c50;
    font-size: 48px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .account__info h1 span a {
    color: #bf1b1b;
    font-size: 16px;
    text-decoration: underline;
  }
  .account__info p {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #192c50;
  }
  .account__info a {
    display: block;
    margin-top: 8px;
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #192c50;
  }
  .account__sidebar {
    padding-right: 58px;
    border-right: 1px solid #dbdbdb;
    height: auto;
  }
  .account__sidebar ul li {
    margin-bottom: 16px;
  }
  .account__sidebar ul li.active {
    display: flex;
    align-items: center;
  }
  .account__sidebar ul li.active:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: #bf1b1b;
    border-radius: 50%;
    margin-right: 8px;
  }
  .account__sidebar ul li.active a {
    color: #bf1b1b;
  }
  .account__sidebar ul li a {
    font-size: 16px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #192c50;
  }
  .account__sidebar ul li a:hover {
    color: #bf1b1b;
  }
  .cart {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  .cart__grid {
    display: flex;
    justify-content: space-between;
  }
  .cart__but {
    margin-top: 54px;
  }
  .cart__but button {
    background: unset;
    border: unset;
    cursor: pointer;
  }
  .cart__amount {
    width: 80px;
  }
  .cart__amount .stepper__btn {
    background: unset;
    border: unset;
    cursor: pointer;
  }
  .cart__amount .stepper {
    display: flex;
    justify-content: flex-end;
    width: 80px;
    position: relative;
    margin-top: 30px;
  }
  .cart__amount .stepper input {
    width: 50px;
    text-align: center;
    border: 0;
    outline: 0;
    font-family: "Noto Sans", sans-serif;
    font-size: 18px;
    color: #000;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .cart__amount .stepper input ::placeholder {
    text-align: center;
  }
  .cart__amount .stepper .stepper__btn--up {
    position: absolute;
    top: 3px;
    right: -16px;
  }
  .cart__amount .stepper .stepper__btn--down {
    position: absolute;
    left: 14px;
    top: 3px;
  }
  .cart__item {
    display: flex;
    width: 664px;
    position: relative;
    margin: 48px 0;
  }
  .cart__item:last-child:after {
    display: none;
  }
  .cart__item:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #aaa;
    position: absolute;
    bottom: -24px;
  }
  .cart__price {
    display: block;
    text-align: right;
    font-family: "Noto Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #192c50;
    margin-top: 4px;
  }
  .cart__block {
    margin-left: 30px;
    width: 325px;
    margin-right: 70px;
  }
  .cart__article {
    margin-bottom: 60px;
  }
  .cart__action {
    width: 100px;
    text-align: right;
  }
  .cart__top {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 664px;
    margin-top: 30px;
    margin-bottom: 25px;
    color: #aaa;
  }
  .cart__title {
    color: #192c50;
    font-size: 24px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 12px;
  }
  .cart__article {
    color: #aaa;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
  }
  .cart h1 {
    font-size: 48px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: #192c50;
  }
  .cart__size {
    color: #192c50;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .cart__size span {
    margin-left: 30px;
  }
  .cart__color {
    color: #192c50;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .cart__color span {
    margin-left: 48px;
  }
  .cart__info {
    width: 75%;
  }
  .cart__info h2 {
    margin-bottom: 35px;
    font-size: 32px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-top: 65px;
    color: #192c50;
  }
  .cart__fixed {
    background-color: #192c50;
    width: 380px;
    height: max-content;
    position: sticky;
    overflow-y: scroll;
    padding: 32px;
    color: #fff;
  }
  .cart__fixed h2 {
    margin-bottom: 70px;
    font-size: 32px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
  .cart__fixed span {
    display: block;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .cart__fixed small {
    display: block;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 16px;
  }
  .cart__fixed p {
    font-size: 32px;
    font-family: "Noto Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
.form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.form .radio-select {
  padding-top: 24px;
  padding-bottom: 18px;
}
.form .radio-select label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align-last: left;
}
.form .radio-select label span {
  display: inline-block;
  margin-left: 10px;
  color: #000000;
}
.form .radio-select input {
  width: 20px;
  height: 20px;
}
.form__col {
  width: 352px;
}
.form__item {
  margin-bottom: 16px;
}
.form__item label {
  display: block;
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  color: #192c50;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 10px;
}
.form__item textarea {
  padding: 16px;
  resize: none;
  height: 176px;
  border: 1px solid #aaa;
  border-radius: 10px;
  width: 100%;
  color: #aaa;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
}
.form__item input {
  border: 1px solid #aaa;
  padding: 16px;
  display: block;
  border-radius: 10px;
  width: 100%;
}
.form__item::placeholder {
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: #aaa;
}
.form__item button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 16px 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #bf1b1b;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.footer {
  position: relative;
}
.footer__col:nth-child(2) {
  order: 1;
}
.footer__col:nth-child(1) {
  order: 2;
}
.footer__bottom {
  margin-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
}
.footer__bottom h3 {
  color: #192c50;
  font-size: 16px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 28px;
}
.footer__bottom a {
  color: #000;
  line-height: 22px;
}
.footer__bottom p {
  display: block;
  color: #000;
  margin-bottom: 22px;
}
.footer__bottom .footer__col:nth-child(3) {
  display: none;
}
.footer__bottom .footer__col:nth-child(3) p {
  width: 269px;
}
.footer__bottom .footer__col:nth-child(3) p a {
  color: #192c50;
  font-weight: bold;
  text-decoration: underline;
}
.footer:after {
  content: "";
  display: flex;
  position: absolute;
  width: 70%;
  height: 70%;
  background-image: url("../img/footerbg.png");
  background-size: contain;
  background-position: center right;
  right: 0;
  bottom: -50px;
  background-repeat: no-repeat;
  z-index: -1;
}

@media (min-width: 980px) {
  .footer {
    position: relative;
  }
  .footer__col:nth-child(2) {
    order: 2;
  }
  .footer__col:nth-child(1) {
    order: 1;
  }
  .footer__col:nth-child(3) {
    order: 3;
  }
  .footer__bottom {
    margin-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 180px;
  }
  .footer__bottom h3 {
    color: #192c50;
    font-size: 16px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 28px;
  }
  .footer__bottom a {
    color: #000;
    line-height: 22px;
  }
  .footer__bottom p {
    display: block;
    color: #000;
    margin-bottom: 22px;
  }
  .footer__bottom .footer__col:nth-child(3) {
    display: block;
  }
  .footer__bottom .footer__col:nth-child(3) p {
    width: 269px;
  }
  .footer__bottom .footer__col:nth-child(3) p a {
    color: #192c50;
    font-weight: bold;
    text-decoration: underline;
  }
  .footer:after {
    content: "";
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../img/footerbg.png");
    background-size: contain;
    background-position: center right;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    z-index: -1;
  }
}