.products {
  &__list {
    grid-column: 2span;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
  &__item {
    margin-bottom: 0px;
    border-radius: 15px;
    transition: 0.3s all;
    padding: 8px;
    transition: all 0.3s ease;
    position: relative;

    &--preview {
      opacity: 0;
      background: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: 45%;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 12px 0;
      overflow: hidden;
      a {
        font-size: 14px;
        color: #aaa;
        cursor: pointer;
      }
    }

    &:hover {
      .products__item--preview {
        opacity: 1;
      }
      .products__item--cart {
        visibility: visible;
        opacity: 1;
      }
    }

    &--big {
      grid-column: 2 span;
      .products__item--text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          margin: 0;
        }
      }
    }
    &:hover {
      background: #fff;
      box-shadow: 0px 10px 17px 0px rgba(0, 0, 0, 0.15);

      background-color: #fff;
    }
    &--text {
      color: #192c50;
      h3 {
        display: block;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;
        font-weight: 500;
        line-height: 20px;
        margin-top: 12px;
      }
      span {
        display: block;
        font-size: 20px;
        font-family: "Noto Sans", sans-serif;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 20px;
      }
      small {
        display: block;
        color: #aaaaaa;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 12px;
      }
    }
    &--cart {
      visibility: hidden;
      opacity: 0;
    }
    &--add {
      display: block;
      width: 100%;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: #bf1b1b;
      color: #fff;
      font-size: 12px;
      font-family: "Noto Sans", sans-serif;
      font-weight: 500;
      line-height: 20px;
      border: 0;
      border-radius: 10px;
      cursor: pointer;
    }
  }
  &__pagination {
    margin-top: 50px;
    margin-bottom: 180px;
    display: flex;
    justify-content: center;
  }
  &__showmore {
    button {
      color: #bf1b1b;
      border: 1px solid #bf1b1b;
      padding: 16px 32px;
      background-color: #fff;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      border-radius: 10px;
      transition: all 0.3s ease;
      &:hover {
        background-color: #bf1b1b;
        color: #fff;
      }
    }
  }
}
@media (min-width: 980px) {
  .products {
    &__list {
      grid-column: 4 span;
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(4, 1fr);
    }
    &__item {
      margin-bottom: 0px;
      border-radius: 15px;
      transition: 0.3s all;
      padding: 8px;
      transition: all 0.3s ease;
      position: relative;

      &:hover {
        .color {
          visibility: visible;
          margin-top: 10px;
        }
      }

      .color {
        visibility: hidden;
        margin-top: 0px;
        &__list {
          display: flex;
          div {
            width: 16px;
            height: 16px;
            cursor: pointer;
            border-radius: 32px;
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }

      &--preview {
        opacity: 0;
        background: rgba(255, 255, 255, 0.6);
        position: absolute;
        top: 55%;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 12px 0;
        overflow: hidden;
        span {
          font-size: 14px;
          color: #aaa;
          cursor: pointer;
        }
      }

      &:hover {
        .products__item--preview {
          opacity: 1;
        }
        .products__item--cart {
          visibility: visible;
          opacity: 1;
        }
      }

      &--big {
        grid-column: 2 span;
        .products__item--text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            margin: 0;
          }
        }
      }
      &:hover {
        background: #fff;
        box-shadow: 0px 10px 17px 0px rgba(0, 0, 0, 0.15);

        background-color: #fff;
      }
      &--text {
        color: #192c50;
        h3 {
          display: block;
          font-size: 14px;
          font-family: "Noto Sans", sans-serif;
          font-weight: 500;
          line-height: 20px;
          margin-top: 12px;
          a {
            color: #192c50;
          }
        }
        span {
          display: block;
          font-size: 20px;
          font-family: "Noto Sans", sans-serif;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 20px;
        }
        small {
          display: block;
          color: #aaaaaa;
          font-size: 14px;
          font-family: "Noto Sans", sans-serif;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 12px;
        }
      }
      &--cart {
        visibility: hidden;
        opacity: 0;
      }
      &--add {
        display: block;
        width: 100%;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: #bf1b1b;
        color: #fff;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-weight: 500;
        line-height: 20px;
        border: 0;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    &__pagination {
      margin-top: 50px;
      margin-bottom: 180px;
      display: flex;
      justify-content: center;
    }
    &__showmore {
      button {
        color: #bf1b1b;
        border: 1px solid #bf1b1b;
        padding: 16px 32px;
        background-color: #fff;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        border-radius: 10px;
        transition: all 0.3s ease;
        &:hover {
          background-color: #bf1b1b;
          color: #fff;
        }
      }
    }
  }
}
