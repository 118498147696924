.footer {
  position: relative;

  &__col {
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(1) {
      order: 2;
    }
  }

  &__bottom {
    margin-top: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    h3 {
      color: #192c50;
      font-size: 16px;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      margin-bottom: 28px;
    }
    a {
      color: #000;
      line-height: 22px;
    }
    p {
      display: block;
      color: #000;
      margin-bottom: 22px;
    }
    .footer__col {
      &:nth-child(3) {
        display: none;
        p {
          width: 269px;
          a {
            color: #192c50;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
  }

  &:after {
    content: "";
    display: flex;
    position: absolute;
    width: 70%;
    height: 70%;
    background-image: url("../img/footerbg.png");
    background-size: contain;
    background-position: center right;
    right: 0;
    bottom: -50px;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
@media (min-width: 980px) {
  .footer {
    position: relative;
    &__col {
      &:nth-child(2) {
        order: 2;
      }
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(3) {
        order: 3;
      }
    }
    &__bottom {
      margin-top: 40px;
      padding-bottom: 40px;
      display: flex;
      flex-wrap: wrap;
      gap: 180px;
      h3 {
        color: #192c50;
        font-size: 16px;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 28px;
      }
      a {
        color: #000;
        line-height: 22px;
      }
      p {
        display: block;
        color: #000;
        margin-bottom: 22px;
      }
      .footer__col {
        &:nth-child(3) {
          display: block;
          p {
            width: 269px;
            a {
              color: #192c50;
              font-weight: bold;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &:after {
      content: "";
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../img/footerbg.png");
      background-size: contain;
      background-position: center right;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
}
