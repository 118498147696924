// базовые подключения
@import "vars";
@import "mixins";
@import "fonts";
@import "settings";

// подключения компонентов страницы
@import "./components/header";
@import "./components/hero";
@import "./components/latest-new";
@import "./components/products";
@import "./components/banner";
@import "./components/card";

@media (min-width: 980px) {
  .breadcrumbs {
    margin-bottom: 37px;
    ul {
      display: flex;
      li {
        padding: 0px 10px;
        position: relative;
        &:first-child {
          padding-left: 0;
          &:after {
            content: "-";
            display: none;
          }
        }
        &:after {
          content: "-";
          display: block;
          position: absolute;
          top: 0;
          left: -2px;
        }
        &.actives {
          a {
            color: #192c50;
          }
        }
        a {
          color: #aaa;
          font-size: 12px;
          font-family: "Noto Sans", sans-serif;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }

  .textpage {
    margin-top: 160px;
    margin-bottom: 160px;
    &__grid {
      display: flex;
      flex-wrap: wrap;
    }
    &__content {
      padding-left: 23px;
      h1 {
        color: #192c50;
        font-size: 48px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 24px;
      }
      p {
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #192c50;
      }
      a {
        display: block;
        margin-top: 50px;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        text-decoration: underline;
        color: #aaa;
      }
    }
    &__sidebar {
      padding-right: 58px;
      border-right: 1px solid #dbdbdb;
      height: 285px;

      ul {
        li {
          margin-bottom: 16px;
          &.active {
            display: flex;
            align-items: center;
            &:before {
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              background: #bf1b1b;
              border-radius: 50%;
              margin-right: 8px;
            }
            a {
              color: #bf1b1b;
            }
          }
          a {
            font-size: 16px;
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            color: #192c50;
            &:hover {
              color: #bf1b1b;
            }
          }
        }
      }
    }
  }

  .account {
    margin-top: 160px;
    margin-bottom: 160px;

    &__grid {
      display: flex;
      flex-wrap: wrap;
    }

    .form {
      flex-direction: column;
      width: 734px;
      &__item {
        margin: 0;
      }

      &__saler {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        input {
          width: 352px;
        }
        button {
          width: 100%;
          display: flex;
          width: 722px;
          padding: 16px 32px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          background-color: #aaa;
          color: #fff;
          border: none;
          cursor: pointer;
        }
      }

      .saler-title {
        font-size: 24px;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }

      &__line {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 56px;
        input {
          width: 352px;
        }
      }

      .edit {
        display: inline-block;
        margin-top: 35px;
        width: 180px;
        cursor: pointer;
        img {
          transform: rotate(180deg);
          position: relative;
          top: -2px;
          left: 5px;
        }
        &.act {
          img {
            transform: rotate(0deg);
          }
        }
      }

      &__password {
        display: none;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 56px;

        &.activeblock {
          display: flex;
        }

        input {
          width: 352px;
        }
        button {
          width: 352px;
          display: block;
          background-color: #aaa;
          color: #fff;
          position: relative;
          top: 26px;
        }
      }
    }

    &__info {
      padding-left: 23px;
      h1 {
        color: #192c50;
        font-size: 48px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          a {
            color: #bf1b1b;
            font-size: 16px;
            text-decoration: underline;
          }
        }
      }
      p {
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #192c50;
      }
      a {
        display: block;
        margin-top: 8px;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        color: #192c50;
      }
    }

    &__sidebar {
      padding-right: 58px;
      border-right: 1px solid #dbdbdb;
      height: auto;

      ul {
        li {
          margin-bottom: 16px;
          &.active {
            display: flex;
            align-items: center;
            &:before {
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              background: #bf1b1b;
              border-radius: 50%;
              margin-right: 8px;
            }
            a {
              color: #bf1b1b;
            }
          }
          a {
            font-size: 16px;
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            color: #192c50;
            &:hover {
              color: #bf1b1b;
            }
          }
        }
      }
    }
  }

  .cart {
    margin-top: 160px;
    margin-bottom: 160px;
    &__grid {
      display: flex;
      justify-content: space-between;
    }

    &__but {
      margin-top: 54px;
      button {
        background: unset;
        border: unset;
        cursor: pointer;
      }
    }

    &__amount {
      width: 80px;
      .stepper__btn {
        background: unset;
        border: unset;
        cursor: pointer;
      }
      .stepper {
        display: flex;
        justify-content: flex-end;
        width: 80px;
        position: relative;
        margin-top: 30px;
        input {
          width: 50px;
          text-align: center;
          border: 0;
          outline: 0;
          font-family: "Noto Sans", sans-serif;
          font-size: 18px;
          color: #000;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          ::placeholder {
            text-align: center;
          }
        }
        .stepper__btn--up {
          position: absolute;
          top: 3px;
          right: -16px;
        }
        .stepper__btn--down {
          position: absolute;
          left: 14px;
          top: 3px;
        }
      }
    }

    &__item {
      display: flex;
      width: 664px;
      position: relative;
      margin: 48px 0;
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #aaa;
        position: absolute;
        bottom: -24px;
      }
    }

    &__price {
      display: block;
      text-align: right;
      font-family: "Noto Sans", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #192c50;
      margin-top: 4px;
    }

    &__block {
      margin-left: 30px;
      width: 325px;
      margin-right: 70px;
    }

    &__article {
      margin-bottom: 60px;
    }

    &__action {
      width: 100px;
      text-align: right;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      width: 664px;
      margin-top: 30px;
      margin-bottom: 25px;
      color: #aaa;
    }

    &__title {
      color: #192c50;
      font-size: 24px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 12px;
    }
    &__article {
      color: #aaa;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
    }

    h1 {
      font-size: 48px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #192c50;
    }

    &__size {
      color: #192c50;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;
      span {
        margin-left: 30px;
      }
    }

    &__color {
      color: #192c50;
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      span {
        margin-left: 48px;
      }
    }

    &__info {
      width: 75%;
      h2 {
        margin-bottom: 35px;
        font-size: 32px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-top: 65px;
        color: #192c50;
      }
    }
    &__fixed {
      background-color: #192c50;
      width: 380px;
      height: max-content;
      position: sticky;
      overflow-y: scroll;

      padding: 32px;
      color: #fff;
      h2 {
        margin-bottom: 70px;
        font-size: 32px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
      span {
        display: block;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 16px;
      }
      small {
        display: block;
        font-size: 14px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 16px;
      }
      p {
        font-size: 32px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .radio-select {
    padding-top: 24px;
    padding-bottom: 18px;
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align-last: left;
      span {
        display: inline-block;
        margin-left: 10px;
        color: #000000;
      }
    }
    input {
      width: 20px;
      height: 20px;
    }
  }

  &__col {
    width: 352px;
  }
  &__item {
    margin-bottom: 16px;

    label {
      display: block;
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;
      color: #192c50;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 10px;
    }

    textarea {
      padding: 16px;
      resize: none;
      height: 176px;
      border: 1px solid #aaa;
      border-radius: 10px;
      width: 100%;
      color: #aaa;
      font-size: 14px;
      font-family: "Noto Sans", sans-serif;
    }

    input {
      border: 1px solid #aaa;
      padding: 16px;
      display: block;
      border-radius: 10px;

      width: 100%;
    }
    &::placeholder {
      font-size: 14px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #aaa;
    }
    button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      padding: 16px 10px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: #bf1b1b;
      color: #fff;
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

@import "./components/footer";
