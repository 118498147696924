@media (min-width: 980px) {
  .card {
    margin-top: 160px;
    margin-bottom: 160px;
    &__top {
      display: flex;
      height: 630px;

      .slider-nav {
        width: 160px;
        margin-right: 30px;
        img {
          height: 160px;
        }
      }
      .slider-block {
        overflow: hidden;
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        border-radius: 14.111px;
      }
    }
    &__bottom {
      margin-top: 100px;
    }
    .title {
      font-size: 32px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      color: #192c50;
      margin-bottom: 10px;
    }
    .vendor {
      font-size: 16px;
      font-family: "Noto Sans", sans-serif;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #aaa;
    }
    &__slider {
      width: 592px;
      height: 630px;
      margin-right: 30px;
      display: flex;
      justify-content: space-between;
    }
    &__info {
      width: calc(100% - 592px - 60px);
      padding-right: 62px;
    }
    .price {
      margin-top: 60px;
      margin-bottom: 48px;
      &__current {
        color: #192c50;
        font-size: 48px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        margin-top: 10px;
      }
      &__old {
        font-size: 24px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration: line-through;
        color: #dbdbdb;
      }
    }
    .color {
      span {
        display: block;
        color: #aaaaaa;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 16px;
        b {
          color: #192c50;
          font-weight: 500;
          font-family: "Noto Sans", sans-serif;
        }
      }
      &__list {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        margin-bottom: 35px;

        div {
          width: 32px;
          height: 32px;
          border-radius: 32px;
          cursor: pointer;
        }
      }
    }

    .size {
      span {
        display: block;
        color: #aaaaaa;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 16px;
        b {
          color: #192c50;
          font-weight: 500;
          font-family: "Noto Sans", sans-serif;
        }
      }
      &__list {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        div {
          display: flex;
          width: 50px;
          height: 50px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          border-radius: 10px;
          border: 1px solid #f2f2f2;
        }
      }
    }

    .tablesize {
      display: block;
      padding-top: 16px;
      padding-bottom: 40px;
      a {
        color: #aaaaaa;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    &__description {
      margin-bottom: 48px;
      h3 {
        color: #192c50;
        font-size: 24px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 24px;
      }
      p {
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #4f4f4f;
        max-width: 980px;
      }
    }

    &__characteristics {
      margin-bottom: 48px;
      h3 {
        color: #192c50;
        font-size: 24px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }

    &__setka {
      margin-bottom: 48px;
      h3 {
        color: #192c50;
        font-size: 24px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }

    &__status {
      margin-bottom: 48px;
      span {
        color: #192c50;
        font-size: 24px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }

    .characteristics {
      width: 100%;
      ul {
        li {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 14px;
          span {
            display: block;
          }
          .name {
            margin-right: -30%;
            overflow: hidden;
            vertical-align: top;
            width: 140px;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            &:after {
              content: "";
              display: inline-block;
              width: 100%;
              margin: 0 -99% 0 3px;
              vertical-align: baseline;
              border-bottom: 1px dashed #fff;
            }
          }
          .value {
            display: inline-block;
            letter-spacing: normal;
            padding-left: 3px;
            margin-left: 33%;
            width: 380px;
            vertical-align: bottom;
            color: #606060;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

    .setka {
      width: 100%;
      ul {
        max-width: 1040px;
        li {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 14px;
          span {
            display: block;
          }
          .name {
            margin-right: 0;
            overflow: hidden;
            vertical-align: top;
            width: 140px;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            &:after {
              content: "";
              display: inline-block;
              width: 100%;
              margin: 0 -99% 0 3px;
              vertical-align: baseline;
              border-bottom: 1px dashed #fff;
            }
          }
          .value {
            display: inline-block;
            letter-spacing: normal;
            padding-left: 3px;
            margin-left: 40px;
            width: 20px;
            vertical-align: bottom;
            color: #606060;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-right: 10px;
          }
        }
      }
    }

    &__action {
      button {
        display: flex;
        width: 445px;
        padding: 16px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background: #bf1b1b;
        color: #fff;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-family: "Noto Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
